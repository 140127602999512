import Layout from "components/General/Layout";
import { regions } from "data/general/regions";
import ActionPage from "pages/Agenda pages/ActionPage";
import ActionsCulturellesPage from "pages/Agenda pages/ActionsCulturellesPage";
import AgendaCulturelAlger from "pages/Agenda pages/AgendaCulturelAlger";
import EventPage from "pages/Agenda pages/EventPage";
import CityHomePage from "pages/Cities/CityHomePage";
import CoursPresentationPage from "pages/Cours de français/CoursPresentationPage";
import DetailCours from "pages/Cours de français/DetailCours";
import Bourses from "pages/Etudes/Bourses";
import CampusFrance from "pages/Etudes/CampusFrance";
import EssaPage from "pages/Etudes/EsaaPage";
import EtudesPage from "pages/Etudes/EtudesPage";
import EtudierEnAlgerie from "pages/Etudes/EtudierEnAlgerie";
import EtudierEnFrance from "pages/Etudes/EtudierEnFrance";
import AlumniFrance from "pages/Etudes/FranceAlumni";
import LiadPage from "pages/Etudes/LiadPage";
import PehPage from "pages/Etudes/PehPage";
import InstitutEquipePage from "pages/institut/InstitutEquipePage";
import InstitutMissionsPage from "pages/institut/InstitutMissionsPage";
import InstitutPresentationPage from "pages/institut/InstitutPresentationPage";
import InstitutRecrutementPage from "pages/institut/InstitutRecrutementPage";
import InstitutVilleEquipePage from "pages/institut/InstitutVilleEquipePage";
import InstitutVillePresentationPage from "pages/institut/InstitutVillePresentationPage";
import MissionFour from "pages/institut/MissionFour";
import MissionOne from "pages/institut/MissionOne";
import MissionThree from "pages/institut/MissionThree";
import MissionTwo from "pages/institut/MissionTwo";
import MediathequeAteliersPage from "pages/mediatheques/MediathequeAteliersPage";
import MediathequeCulturethequePage from "pages/mediatheques/MediathequeCulturethequePage";
import MediathequeInscriptionPage from "pages/mediatheques/MediathequeInscriptionPage";
import MediathequePresentationPage from "pages/mediatheques/MediathequePresentationPage";
import DelfDalfPage from "pages/Tests Et Examens/DelfDalfPage";
import DetailsTestPage from "pages/Tests Et Examens/DetailsTestPage";
import TCFpage from "pages/Tests Et Examens/TCFpage";
import TestsPresentationPage from "pages/Tests Et Examens/TestsPresentationPage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Details from "./pages/blog/Detail";
import MediathequeIndex from "./pages/mediatheques/MediathequeIndex";
import MyGallery from "./pages/page/GalleryPage";
import LandingPage from "./pages/page/LandingPage";
// import context
import AgendaPage2 from "pages/Agenda pages/AgendaPage2";
import JeunessePage from "pages/Agenda pages/JeunessePage";
import ReservationPageTest from "pages/Cours de français/ReservationTestPage";
import TousLesCoursAEC from "pages/Cours de français/TousLesCoursAEC";
import MediathequeIndex2 from "pages/mediatheques/MediathequeIndex2";
import AccountPage from "pages/Student/AccountPage";
import CartPage from "pages/Student/CartPage";
import IdentificationPage from "pages/Student/IdentificationPage";
import LoginPage from "pages/Student/LoginPage";
import ReminderUpdatePasswordPage from "pages/Student/ReminderUpdatePasswordPage";
import UpdatePasswordPage from "pages/Student/UpdatePasswordPage";
import { GlobalProvider } from "./contexts/GlobalContext";
import Credit from "pages/Credit/Credit";
import LiensUtiles from "pages/Credit/LiensUtiles";
import MentionsLegales from "pages/Credit/MentionsLegales";
import SamplePage from "pages/EspacesPage";
import SamplePage1 from "pages/SamplePage1";
import SamplePage2 from "pages/SamplePage2";
import SamplePage3 from "pages/SamplePage3";
import ResultatPage from "pages/ResultatPage";
import React, { useContext } from 'react';
import { getPages } from './services/posts';
import { PageSite, PostSite, PostsSite } from './components/blog';
import EspacePage from "pages/Agenda pages/EspacePage";
import EspacesPage from "pages/EspacesPage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import EventAecPage from "pages/Student/EventAecPage";
import { LanguageContext } from "services/LanguageContext";
import i18n from "i18n";


function App() {
  const [pages, setPages] = React.useState([]);
  const { language } = useContext(LanguageContext);
    /*React.useEffect(() => {
        async function fetchData() {
            const results = await getPages();
    
            setPages(results.pages);
        }

        fetchData();
    }, []);*/

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBwi4qjR5tuudPzbubAA9im4yJ6V6Uu3nA",
  authDomain: "ifa-paiement.firebaseapp.com",
  projectId: "ifa-paiement",
  storageBucket: "ifa-paiement.appspot.com",
  messagingSenderId: "121730147826",
  appId: "1:121730147826:web:faaab36181735e1dc7b76b",
  measurementId: "G-Z2XF81LZ22"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);

  return (
    <GlobalProvider>
      <div className="App">
      {" "}
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout home='/'/>}>

                    
                    
            {/* for random routes : Redirect user to the home page */}
            <Route path="*" element={ <Navigate to="/" /> }/>
                <Route index element={<LandingPage/>}/>
                <Route path="connexion" element={<LoginPage/>}/>
                <Route path="mon-compte" element={<AccountPage/>}/>
                <Route path="aec-event" element={<EventAecPage/>}>
                    <Route path=":eventId" element={<EventAecPage/>}/>
                      </Route>
                <Route path="panier" element={<CartPage/>}/>
                <Route path="changer-le-mot-de-passe" element={<UpdatePasswordPage/>}/>
                <Route path="identification" element={<IdentificationPage/>}/>
                <Route path="reinitialisation-de-mot-de-passe" element={<ReminderUpdatePasswordPage/>}/>
                <Route path="partenaires" element={<Credit/>}/>
                <Route path="liens-utiles" element={<LiensUtiles/>}/>
                <Route path="mentions-legales" element={<MentionsLegales/>}/>             
                <Route path='blog'element={
                        <PostsSite />}/>
                    <Route path='blog/:id/:slug/' element={
                        <PostSite />
                      }/>
                    <Route path='pages/:slug/' element={
                        <PageSite />
                      }/>
                <Route path="institut" >
                  <Route index element={<InstitutPresentationPage ville="national"/>}/>
                  <Route path="presentation" element={<InstitutPresentationPage ville="national"/>} />
                  <Route path="equipe" element={<InstitutEquipePage/>}/>
                  <Route path="recrutement" element={<InstitutRecrutementPage ville="national"/>}/>
                  <Route path="nos-espaces">
                    <Route index element={<EspacesPage region="national" title="Nos espaces"/>}/>
                      <Route path=":action_url/:slug" element={<EspacePage city="national"/>}/>
                      </Route> 
                  <Route path="appels-projets" element={<SamplePage1 region="national" title="APPELS À PROJETS"/>}/>
                  <Route path="bourses" element={<SamplePage2 region="national" title="BOURSES & CANDIDATURES"/>}/>
                  <Route path="identity">
                          <Route path=":title_url/:slug/" element={<SamplePage3 region={"national"}/>} >
                          </Route>
                      </Route>
                  <Route path="nos-missions" >
                    <Route index element={<InstitutMissionsPage ville="national"/>} />
                    <Route path=":title_url/:slug/" element={<MissionOne ville="national"/>}/>
                    <Route path="Mission-de-cooperation-institutionnelle" element={<MissionTwo/>}/>
                    <Route path="Mission-de-cooperation-culturelle" element={<MissionThree/>}/>
                    <Route path="Mission-de-cooperation-universitaire" element={<MissionFour/>}/>
                  </Route>
                  
                </Route>

                <Route path="tests-et-examens" >
                    <Route index element={<TestsPresentationPage/>}/>
                    <Route path="presentation">
                      <Route index element={< TestsPresentationPage />} />
                      <Route path="tcf">
                          <Route index element={<TCFpage region="national"/>} />
                          <Route path=":test_title" element={<DetailsTestPage region="national"/>} >
                          </Route>
                      </Route>
                      <Route path="delf-et-dalf" >
                        <Route index element={<DelfDalfPage region="national"/>}/>
                        <Route path=":test_title" element={<DetailsTestPage region="national"/>}>
                        
                        </Route>
                      </Route>
                    </Route>
                    <Route path="tcf">
                        <Route index element={<TCFpage/>} region="national"/>
                        <Route path=":test_title">
                          <Route index element={<DetailsTestPage region="national"/>} />
                        <Route path="resultats" element={<ResultatPage region="national"/>}/>
                        
                        </Route>
                    </Route>
                    <Route path="delf-et-dalf">
                      <Route index element={<DelfDalfPage/>}/>
                      <Route path=":test_title">
                        <Route index element={<DetailsTestPage region="national"/>}/>
                        <Route path="resultats" element={<ResultatPage region="national"/>}/>
                        <Route path="inscription" element={<ResultatPage region="national"/>}/>
                      
                      </Route>
                    </Route>
                    
                </Route>
                
                <Route path="cours-de-francais" >
                  <Route index element={<CoursPresentationPage ville="national"/>}/>
                  <Route path="tous-les-cours" element={<TousLesCoursAEC ville="national"/>}/>
                  <Route path=":id" >
                    <Route index element={<DetailCours region="national"/>}/>
                    <Route path="reserver-un-test" element={<ReservationPageTest/>} />
                    <Route path="resultats" element={<ResultatPage region="national"/>}/>
                  </Route>
                  
                </Route>

                <Route path="etudes"  >
                  <Route index element={<EtudesPage/>}/>
                  <Route path="en-france"  >
                  <Route index element={<EtudierEnFrance/>}/>

                      <Route path="campus-france" >
                        <Route index element={<CampusFrance region = "national"/>}/>
                        <Route path=":id" element={<EventPage/>} />
                      </Route>
                      <Route path="france-alumni">
                        <Route index element={<AlumniFrance />}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>
                      <Route path="bourses" element={<Bourses/>} />
                  </Route>
                  
                  <Route path="en-algerie">
                      <Route index element={<EtudierEnAlgerie/>} />
                      <Route path="liad" >
                        <Route index element={<LiadPage/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>
                      <Route path="peh" >
                        <Route index element={<PehPage/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>
                      <Route path="essa" >
                        <Route index element={<EssaPage/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>
                  </Route>
                </Route>

                {/* <Route path="agenda-culturel" element={<AgendaCulturel/>} /> */}
                <Route path="culture"  >
                    {/* <Route index element={<AgendaPage city="national"/>}/> */}
                    <Route index element={<AgendaPage2 city="national"/>}/>
                    <Route path="students/login" element={<LoginPage/>}/>
                    <Route path="mon-compte" element={<AccountPage/>}/>
                    <Route path="aec-event" element={<EventAecPage/>}>
                    <Route path=":eventId" element={<EventAecPage/>}/>
                      </Route>
                    <Route path="students/update-password" element={<UpdatePasswordPage/>}/>
                    <Route path="students/identification" element={<IdentificationPage/>}/>
                    <Route path="students/reset" element={<ReminderUpdatePasswordPage/>}/>
                    <Route path="carts/view" element={<CartPage/>}/>
                    <Route path="agenda" >
                        {/* <Route index element={<AgendaPage city="national"/>}/> */}
                        <Route index element={<AgendaPage2 city="national" type="all"/>}/>
                        <Route path="musique" >
                          {/* <Route index element={<AgendaPage type="musique" city="national"/>}/> */}
                          {/* <Route index element={<MusiquePage city="national"/>}/> */}
                          <Route index element={<AgendaPage2 city="national" type="musique"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="theatre" >
                          {/* <Route index element={<AgendaPage type="théatre" city="national"/>}/> */}
                          {/* <Route index element={<TheatrePage city="national"/>}/> */}
                          <Route index element={<AgendaPage2 city="national" type="théatre"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="danse" >
                          {/* <Route index element={<AgendaPage type="danse" city="national"/>}/> */}
                          {/* <Route index element={<DansePage city="national"/>}/> */}
                          <Route index element={<AgendaPage2 city="national" type="danse"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="expo" >
                          {/* <Route index element={<AgendaPage type="expo" city="national"/>}/> */}
                          {/* <Route index element={<ExpoPage  city="national"/>}/> */}
                          <Route index element={<AgendaPage2 city="national" type="expo"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="cinema" >
                          {/* <Route index element={<AgendaPage type="cinéma" city="national"/>}/> */}
                          {/* <Route index element={<CinemaPage  city="national"/>}/> */}
                          <Route index element={<AgendaPage2 city="national" type="cinéma"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="conferences-et-rencontres" >
                          {/* <Route index element={<AgendaPage type="conférencesetrencontres"city="national"/>}/> */}
                          {/* <Route index element={<ConferencesPage city="national"/>}/> */}
                          <Route index element={<AgendaPage2 city="national" type="conférencesetrencontres"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="jeunesse" >
                          {/* <Route index element={<AgendaPage type="jeunesse"city="national"/>}/> */}
                          <Route index element={<AgendaPage2 city="national" type="jeunesse"/>}/>
                          <Route index element={<JeunessePage city="national"/>}/>
                        </Route>    
                        <Route path="ateliers" >
                          {/* <Route index element={<AgendaPage type="jeunesse"city="national"/>}/> */}
                          <Route index element={<AgendaPage2 city="national" type="ateliers"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                    </Route>  
                    <Route path="nos-actions-culturelles" >
                      <Route index element={<ActionsCulturellesPage city="national"/>}/>
                      <Route path=":action_url/:slug" element={<ActionPage city="national"/>}/>
                    </Route>
                </Route>

                <Route path="mediatheque" >
                  <Route index element={<MediathequeIndex2/>} />
                  <Route path="presentation" element={<MediathequeIndex/>}/>
                  <Route path="inscription" element={<MediathequeIndex/>}/>
                  
                  <Route path="culturetheque" element={<MediathequeIndex/>}/>
                </Route>

                
                
            </Route>

  
            {regions.map(region=>{
              const city = region.name.toLocaleLowerCase()
              return (
                <Route key={region.id} path={`/${city}`} element={<Layout home={`/${city}`}/>} >
                {/* for random routes : Redirect user to the home page */}
                <Route path="*" element={ <Navigate to={`/${city}`} /> }/>
                <Route index element={< CityHomePage ville={city} />}/>
                {/* <Route path=":id" element={<EventPage/>} /> */}
                <Route path="partenaires" element={<Credit ville={city}/>} />
                <Route path="Liens-utiles" element={<LiensUtiles ville={city}/>} />
                <Route path="mentions-legales" element={<MentionsLegales ville={city}/>} />
                

                <Route path="institut" >
                    <Route index element={< InstitutVillePresentationPage ville={city} />} />
                    <Route path="presentation" element={< InstitutVillePresentationPage ville={city}/>} />
                    <Route path="equipe" element={< InstitutVilleEquipePage ville={city}/>} />
                    <Route path="recrutement" element={<InstitutRecrutementPage ville={ city}/>}/>
                    <Route path="nos-espaces">
                    <Route index element={<SamplePage region={city} title="Nos espaces"/>}/>
                      <Route path=":action_url/:slug" element={<ActionPage city={city}/>}/>
                      </Route> 
                    
                    <Route path="appels-projets" element={<SamplePage1 city="national" title="APPELS À PROJETS"/>}/>
                    <Route path="bourses" element={<SamplePage2 city="national" title="BOURSES & CANDIDATURES"/>}/>  
                    <Route path="identity">
                          <Route path=":title_url" element={<SamplePage3 region={city}/>} >
                          </Route>
                      </Route>
                    <Route path="nos-missions" >
                    <Route index element={<InstitutMissionsPage ville={city}/>} />
                      <Route path=":title_url/:slug/" element={<MissionOne ville={city}/> }/>
                      <Route path="Mission-de-cooperation-institutionnelle" element={<MissionTwo/>}/>
                      <Route path="Mission-de-cooperation-culturelle" element={<MissionThree/>}/>
                      <Route path="Mission-de-cooperation-universitaire" element={<MissionFour/>}/>
                    </Route>
                </Route>

                <Route path="tests-et-examens" >
                    <Route index element={<TestsPresentationPage region={city}/>}/>
                    <Route path="presentation">
                      <Route index element={< TestsPresentationPage region={city}/>} />
                      <Route path="tcf">
                          <Route index element={<TCFpage region={city}/>} />
                          <Route path=":test_title">
                             <Route index element={<DetailsTestPage region={city}/>} />
                          <Route path="resultats" element={<ResultatPage region={city}/>}/>
                          
                          </Route>
                      </Route>
                      <Route path="delf-et-dalf">
                        <Route index element={<DelfDalfPage region={city}/>}/>
                        <Route path=":test_title">
                          <Route index element={<DetailsTestPage region={city}/>}/>
                          <Route path="resultats" element={<ResultatPage region={city}/>}/>
                          <Route path="inscription" element={<ResultatPage region={city}/>}/>
                          
                        </Route>
                      </Route>
                    </Route>
                    <Route path="tcf">
                        <Route index element={<TCFpage region={city}/>} />
                        <Route path=":test_title">
                          <Route index element={<DetailsTestPage region={city}/>} />
                        <Route path="resultats" element={<ResultatPage region={city}/>}/>
                        
                        </Route>
                    </Route>
                    <Route path="delf-et-dalf">
                      <Route index element={<DelfDalfPage region={city}/>}/>
                      <Route path=":test_title">
                        <Route index element={<DetailsTestPage region={city}/>}/>
                      <Route path="resultats" element={<ResultatPage region={city}/>}/>
                      <Route path="inscription" element={<ResultatPage region={city}/>}/>
                      </Route>
                    </Route>
                    
                </Route>

                <Route path="cours-de-francais"  >
                    <Route index element={<CoursPresentationPage ville={city}/>} />
                    <Route path="tous-les-cours" element={<TousLesCoursAEC ville={city}/>}/>
                    <Route path="reserver-un-test" element={<ReservationPageTest/>} />
                    {/* <Route path=":id" element={<DetailCours region={city}/>}/> */}
                    <Route path=":id" >
                      <Route index element={<DetailCours region={city}/>}/>
                      <Route path="resultats" element={<ResultatPage region={city}/>}/>
                    </Route>
                </Route> 

                <Route path="etudes"  >
                <Route index element={<EtudesPage/>}/>
                <Route path="en-france"  >
                <Route index element={<EtudierEnFrance/>}/>
                      <Route path="campus-france" >
                        <Route index element={<CampusFrance region ={city}/>}/>
                        <Route path=":id" element={<EventPage/>} />
                      </Route>
                      <Route path="france-alumni">
                        <Route index element={<AlumniFrance region ={city}/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>
                      <Route path="bourses" element={<Bourses/>} />
                  </Route>
                  
                  <Route path="en-algerie">
                      <Route index element={<EtudierEnAlgerie/>} />
                      <Route path="liad" >
                        <Route index element={<LiadPage/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>
                      <Route path="peh" >
                        <Route index element={<PehPage/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>
                      <Route path="essa" >
                        <Route index element={<EssaPage/>}/>
                        <Route path=":id" element={<EventPage/>}/>
                      </Route>
                  </Route>
                </Route>
                
                  <Route path="agenda-culturel" element={<AgendaCulturelAlger/>} />
                  <Route path="culture">
                    {/* <Route index element={<AgendaPage city={city}/>}/> */}
                    <Route index element={<AgendaPage2 city={city}/>}/>
                    <Route path="agenda" >
                        {/* <Route index element={<AgendaPage city={city}/>}/> */}
                        <Route index element={<AgendaPage2 city={city} type="all"/>}/>
                        <Route path="musique" >
                          {/* <Route index element={<AgendaPage city={city} type="musique"/>}/> */}
                          {/* <Route index element={<MusiquePage city={city}/>}/> */}
                          <Route index element={<AgendaPage2 city={city} type="musique"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="theatre" >
                          {/* <Route index element={<AgendaPage city={city} type="théatre"/>}/> */}
                          {/* <Route index element={<TheatrePage city={city}/>}/> */}
                          <Route index element={<AgendaPage2 city={city} type="théatre"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="danse" >
                          {/* <Route index element={<AgendaPage city={city} type="danse"/>}/> */}
                          {/* <Route index element={<DansePage city={city}/>}/> */}
                          <Route index element={<AgendaPage2 city={city} type="danse"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="expo" >
                          {/* <Route index element={<AgendaPage city={city} type="expo"/>}/> */}
                          {/* <Route index element={<ExpoPage  city={city}/>}/> */}
                          <Route index element={<AgendaPage2 city={city} type="expo"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="cinema" >
                          {/* <Route index element={<AgendaPage city={city} type="cinéma"/>}/> */}
                          {/* <Route index element={<CinemaPage  city={city}/>}/> */}
                          <Route index element={<AgendaPage2 city={city} type="cinéma"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="conferences-et-rencontres" >
                          {/* <Route index element={<AgendaPage city={city} type="conférences et rencontres"/>}/> */}
                          {/* <Route index element={<ConferencesPage city={city}/>}/> */}
                          <Route index element={<AgendaPage2 city={city} type="conférencesetrencontres"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>    
                        <Route path="jeunesse" >
                          {/* <Route index element={<AgendaPage city={city} type="jeunesse"/>}/> */}
                          {/* <Route index element={<JeunessePage city={city}/>}/> */}
                          <Route index element={<AgendaPage2 city={city} type="jeunesse"/>}/>
                        </Route>
                        <Route path="ateliers" >
                          {/* <Route index element={<AgendaPage type="jeunesse"city="national"/>}/> */}
                          <Route index element={<AgendaPage2 city={city} type="ateliers"/>}/>
                          <Route path=":id" element={<EventPage/>}/>
                        </Route>      
                    </Route>   
                    <Route path="nos-actions-culturelles" >
                      <Route index element={<ActionsCulturellesPage city={city}/>}/>
                      <Route path=":action_url/:slug" element={<ActionPage city={city}/>}/>
                    </Route>
                </Route>
                  {/* <Route path="mediatheque" element={<RegionMediatheque/>} /> */}
                  <Route path="mediatheque" city={city} >
                      <Route index element={<MediathequePresentationPage ville={city}/>}/>
                      <Route path="presentation" element={<MediathequePresentationPage ville={city}/>}/>
                      <Route path="inscription" element={<MediathequeInscriptionPage ville={city}/>}/>
                      <Route path="culturetheque" element={<MediathequeCulturethequePage ville={city}/>}/>
                  </Route>
            </Route>
              )
            })}

 
            {/* <Route path="/region" element={<RegionMediatheque/>} /> */}
            <Route path="/details" element={<Details/>} />
            <Route path="/gallery" element={<MyGallery/>} />
            <Route path="/media" element={<MediathequeIndex/>} />
            {/* <Route path="/mediatheque" element={<MediathequeIndex/>} /> */}
            {/* <Route path="/institut" element={<Institut/>} /> */}
            <Route path="/mission" element={<MissionOne ville="national"/>} />
            {/* <Route path="/tests-et-examens" element={<TestsEtExamens/>} /> */}
            {/* <Route path="/tests-et-examens/:id" element={<DetailsTestPage/>} /> */}
            <Route path="/etudier-en-france/alumni" element={<AlumniFrance/>} />
            <Route path="/etudier-en-france/bourses" element={<Bourses/>} />
            <Route path="/etudier-en-france/en-algerie" element={<EtudierEnAlgerie/>} />
            <Route path="/etudier-en-france/campus-france" element={<CampusFrance/>} />
            {/* <Route path="/alger" element={<Alger/>} /> */}
            {/* <Route path="/cours-de-francais-alger" element={<CoursDeFrançaisAlger/>} /> */}
            {/* <Route path="/cours-11" element={<DetailCours/>} />
            <Route path="/cours-16" element={<DetailCours16/>} /> */}
            {/* <Route path="/" element={<LandingPage/>} /> */}
            {/* <Route path="/mission" component={Mission} /> */}
            
            
          </Routes>
        </BrowserRouter>
      </div>
    </GlobalProvider>
  );
}

export default App;

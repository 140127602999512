import React,{useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Image, Row, Button,Container } from "react-bootstrap";
import hml from "../../assets/img/homolo.png";
import liad from "../../assets/img/liad.png";
import embassade from "../../assets/img/Ambassade.png";
import compus from "../../assets/img/Campus.png";
import SocialMediaIcons from "../../components/General/SocialMediaIcons"

export default function FooterTop() {


  return (
    <div style={{backgroundColor: "white"}}>
    <Container  className=" pb-3"  >
    <Row
    
      style={{
        padding: "60px 36px 30px ",
        backgroundColor: "white",
        alignItems: "end",
      }}
      
    className="gap-3 mb-2 flex-column flex-md-row align-items-center justify-content-center">
    

      
      <Col  className="my-auto d-flex justify-content-center d-lg-block">
      <a href="https://www.algerie.campusfrance.org/" target="_blank"><Image  width={100} src={compus} /></a>
      </Col>
      <Col  className="my-auto  d-flex justify-content-center d-lg-block">
      <a href="https://dz.ambafrance.org/" target="_blank"><Image height={65}  src={embassade} /></a>
      </Col>
      <Col  className="my-auto  d-flex justify-content-center d-lg-block">
        <a href="https://www.liad-alger.fr/site/index.php?Mn=67&Pg=32                                                             " target="_blank"><Image width={100}  src={liad} /></a>
      </Col>
      <Col  className="my-auto  d-flex justify-content-center d-lg-block">
        <Image height={65}  src={hml} />
      </Col>
      
      
    </Row>
    </Container>
    </div>
  );
}

import Carousel from 'react-bootstrap/Carousel';
import styles from "./etudes.module.css"

const carouselImgs = [
    // Nature
    // "https://images.unsplash.com/photo-1663875967691-15b02702931c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    // "https://images.unsplash.com/photo-1663875972135-bb6654ed702d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80",
    // "https://images.unsplash.com/photo-1580137189272-c9379f8864fd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
    
    // France alumni & campus france
    // "https://images.unsplash.com/photo-1507537509458-b8312d35a233?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    // "https://images.unsplash.com/photo-1491308056676-205b7c9a7dc1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=873&q=80",
    // "https://images.unsplash.com/photo-1536300099515-6c61b290b654?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    // "https://images.unsplash.com/photo-1535982330050-f1c2fb79ff78?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
    {
      img:"https://images.unsplash.com/photo-1507537509458-b8312d35a233?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      type:"campus france"
    },
    {
      img:"https://images.unsplash.com/photo-1491308056676-205b7c9a7dc1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=873&q=80",
      type:"campus france"
    },
    {
      img:"https://images.unsplash.com/photo-1536300099515-6c61b290b654?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      type:"france alumni"
    },
    {
      img:"https://images.unsplash.com/photo-1596495868845-63031cb496da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      type:"france alumni"
    },
    {
      img:"https://images.pexels.com/photos/2781814/pexels-photo-2781814.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      type:"liad"
    },
    {
      img:"https://images.pexels.com/photos/2982449/pexels-photo-2982449.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      type:"liad"
    },
    {
      img:"https://images.pexels.com/photos/10643463/pexels-photo-10643463.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      type:"peh"
    },
    {
      img:"https://images.pexels.com/photos/8363029/pexels-photo-8363029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      type:"peh"
    },
    {
      img:"https://images.unsplash.com/photo-1574958269340-fa927503f3dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=748&q=80",
      type:"esaa"
    },
    {
      img:"https://images.unsplash.com/photo-1527891751199-7225231a68dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      type:"esaa"
    }


    					
]

const  EtudesCarousel=({type})=> {
  // //console.log('========= screen width =======')
  // //console.log(window.innerWidth)
    const filtredImgs = type? carouselImgs.filter(item=>item.type === type) : carouselImgs
    const carouselList = filtredImgs.map((item,index)=>{
        return (
          <Carousel.Item key={index} className={styles.carousel}>
            <img
              className={` ${styles.carousel_img}` } 
              src={item.img}
              alt={`slide ${index}`}
            
            />
          </Carousel.Item>
        );
    })
  return (
    <Carousel fade className='h-100'>
        {carouselList}
    </Carousel>

  );
}

export default EtudesCarousel;